import { eaFetch } from "./ApiUtils";
import { getEnv } from "../config/Config";

export async function verifyRegistrationKey(registrationToken) {
    return eaFetch(
      getEnv("REACT_APP_API_ADMIN"),
      "GET",
      `/registration/${registrationToken}`
    );
  }

export async function verifyRegistrationEmail(registrationToken, email) {
  return eaFetch(
    getEnv("REACT_APP_API_ADMIN"),
    "GET",
    `/registration/${registrationToken}/email?email=${email}`
  );
}

export async function complete(registrationToken, registrationClient) {
  return eaFetch(
    getEnv("REACT_APP_API_ADMIN"),
    "POST",
    `/registration/${registrationToken}`,
    registrationClient
  );
}

export async function completeWithExistingUser(registrationToken, registrationClient) {
  return eaFetch(
    getEnv("REACT_APP_API_ADMIN"),
    "POST",
    `/registration/${registrationToken}/alreadyexists`,
    registrationClient
  );
}

export async function completeWithExistingUserOnAccount(registrationToken, registrationClient) {
  return eaFetch(
    getEnv("REACT_APP_API_ADMIN"),
    "POST",
    `/registration/${registrationToken}/alreadyexistsonaccount`,
    registrationClient
  );
}

export async function retrieveInvitedEmail(registrationToken) {
  return eaFetch(
    getEnv("REACT_APP_API_ADMIN"),
    "POST",
    `/registration/retrieve-email`,
    registrationToken
  );
}