import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nFr from "./i18n-fr.json";
import i18nEn from "./i18n-en.json";
import i18nEs from "./i18n-es.json";
import i18nDe from "./i18n-de.json";
import i18nIt from "./i18n-it.json";
import i18nPt from "./i18n-pt.json";
import i18nNl from "./i18n-nl.json";

i18next
  .use(LanguageDetector)
  .init({
    resources: {
      fr: i18nFr,
      en: i18nEn,
      es: i18nEs,
      de: i18nDe,
      it: i18nIt,
      pt: i18nPt,
      nl: i18nNl
    },
    fallbackLng: "fr"
  });

export const LANGS = ['fr', 'en', 'es', 'de', 'it','pt', 'nl']; 

export default i18next;
