import { useLocation } from "react-router-dom";

export function getEnv(variable) {
  try {
    return process.env[variable] || window.env[variable];
  } catch (e) {
    console.error("Error when getting env variable.");
  }
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
