/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import i18next from "../../i18n/i18n";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      textAlign: "left",
      "& ul": {
        display: "inline-block",
        textAlign: "center",
        padding: "0",
        "& li": {
          display: "inline-block",
          listStyle: "none",
          "& a": {
            color: "white",
            textDecoration: "none",
            padding: "0 1rem",
            borderRight: "1px solid white",
            fontSize: "0.75rem",
            "&:hover": {
              textDecoration: "underline"
            }
          },
          "&:last-child": {
            "& a": {
              borderRight: "none"
            }
          }
        }
      }
    }
  })
);

const EAFooterLinks = () => {
  const css = useStyles();

  return (
    <div className={css.root}>
      <ul>
        <li>
          <a
            href={i18next.t("footerLinks.GDPR.url")}
            target="_blank"
          >
            {i18next.t("footerLinks.GDPR.label")}
          </a>
        </li>
        <li>
          <a
            href={i18next.t("footerLinks.security.url")}
            target="_blank"
          >
            {i18next.t("footerLinks.security.label")}
          </a>
        </li>
        <li>
          <a
            href={i18next.t("footerLinks.CGU.url")}
            target="_blank"
          >
            {i18next.t("footerLinks.CGU.label")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default EAFooterLinks;
