import { Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { retrieveInvitedEmail, verifyRegistrationKey } from "../services/RegistrationService";
import { Context } from "../states/Store";
import { primary, secondary, theme } from "../style/globalStyle";
import RegistrationContainer from "../components/common/RegistrationContainer";
import i18next from "i18next";
import 'react-phone-input-2/lib/style.css';
import { Trans } from "react-i18next";
import { useQuery } from "../config/Config";
import { APPS } from "../services/Constantes";
import RegistrationNotFoundBlock from "./RegistrationNotFoundBlock";
import {useStyles} from "../style/completeStyle";
import ErrorUnknownBlock from "./ErrorUnknownBlock";
import RegistrationFormBlock from "./RegistrationFormBlock";

function Complete() {
  const classes = useStyles();
  const [errorComplete, setErrorComplete] = React.useState(null);
  const [, dispatch] = useContext(Context);
  const [found, setFound] = React.useState(false);
  const app = useQuery().get("app");
  const [registrationTheme, setRegistrationTheme] = React.useState(APPS.includes(app?.toLowerCase()) ? app?.toLowerCase() : "do");
  const [isUserRegistered, setIsUserRegistered] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [appUrl, setAppUrl] = React.useState();
  let { registrationKey } = useParams();
  
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (registrationTheme === 'fo') {
      theme.palette.primary.main = secondary
      theme.palette.secondary.main = primary
      link.href = process.env.PUBLIC_URL + "/favicon_fo.ico";
    } else {
      theme.palette.primary.main = primary
      theme.palette.secondary.main = secondary
      link.href = process.env.PUBLIC_URL + "/favicon_do.ico";
    }
    dispatch({ type: "REGISTRATION_THEME", theme: registrationTheme });
  }, [dispatch, registrationTheme])

  useEffect(() => {
    (async () => {
      try {
        const verificationResp = await verifyRegistrationKey(registrationKey);
        if (verificationResp.httpStatus !== "NOT_FOUND") {
          setRegistrationTheme(APPS.includes(app?.toLowerCase()) ? app?.toLowerCase() : verificationResp.body?.registrationTheme);
          setIsUserRegistered(verificationResp.body?.registered);
          const invitedEmailResponse = await retrieveInvitedEmail(registrationKey)
          if (invitedEmailResponse.httpStatus === "OK") {
            setEmail(invitedEmailResponse.body)
            setFound(true);
          } else {
            setFound(false);
          }
        } else {
          setFound(false);
        }
        setAppUrl(verificationResp.body?.redirectionUrl);
        dispatch({ type: "LOADER", loader: false });
      } catch (e) {
        if (e?.message?.status === 404) {
          setFound(false);
        }
        setErrorComplete(<ErrorUnknownBlock app={registrationTheme}></ErrorUnknownBlock>);
        dispatch({ type: "LOADER", loader: false });
      }
    })();
  }, [dispatch, registrationKey, app, registrationTheme]);


  return (
    <RegistrationContainer>
      {!found && errorComplete && (
        <ErrorUnknownBlock app={registrationTheme}></ErrorUnknownBlock>
      )}
      {!found && appUrl && (
        <Typography className={classes.invalidRegistration}>
          {i18next.t('invalidRegistration1')}<br /><br />
          {i18next.t('invalidRegistration2')}<br /><br />
          {i18next.t('invalidRegistration3')} <a href={appUrl}>{i18next.t('here')}</a>.
        </Typography>
      )}

      {!found && !appUrl && !errorComplete && (
        <Typography className={classes.invalidRegistration}>
          {<RegistrationNotFoundBlock app={registrationTheme}></RegistrationNotFoundBlock>}
        </Typography>
      )}

      {found && isUserRegistered && (
        <Typography className={classes.invalidRegistration}>
          <Trans i18nKey="userAlreadyRegistered" appUrl={appUrl}>
            {i18next.t('userAlreadyRegistered1')}<br /><br />
            {i18next.t('userAlreadyRegistered2')} <a href={appUrl}>{i18next.t('clickHere')}</a>.
          </Trans>
        </Typography>
      )}

      {found && !isUserRegistered && (
        <RegistrationFormBlock email={email} registrationTheme={registrationTheme}></RegistrationFormBlock>
      )}
    </RegistrationContainer>
  );
}

export default Complete;
