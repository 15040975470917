import React from "react";
import i18next from "i18next";
import { Typography } from "@mui/material";
import { Trans } from "react-i18next";
import {useStyles} from "../style/completeStyle";

const ErrorUnknownBlock = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.invalidRegistration}>
        <Trans i18nKey="errors.ERROR_UNKNOWN">
          {i18next.t('errors.ERROR_UNKNOWN')} <a href={i18next.t('support.url')}>{i18next.t('here')}</a>.
        </Trans>
      </Typography>
    </>
  );
};

export default ErrorUnknownBlock;
