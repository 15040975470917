import { createTheme, responsiveFontSizes, adaptV4Theme } from "@mui/material/styles";

export const primary = "#539433";
export const secondary = "#214093";

export const lightGrey = "#F7F7F7";
export const mediumGrey = "#DADADA";
export const darkGrey = "#828282";
export const darkBackground = "#242424";

export const error = "#f44336";

const registrationTheme = createTheme(adaptV4Theme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    background: {
      default: lightGrey
    },
    divider: mediumGrey
  }
}));

export const theme = responsiveFontSizes(registrationTheme);

theme.typography.h5 = {
  ...theme.typography.h5,
  [theme.breakpoints.down('xl')]: {
    fontSize: "1.1rem"
  }
};

theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down('xl')]: {
    marginBottom: "1.2rem"
  }
};
