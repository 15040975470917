import { Button, Container, Paper, Typography } from "@mui/material";
import ScopedCssBaseline from "@mui/material/ScopedCssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Alert, AlertTitle } from '@mui/material';
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import "typeface-roboto";
import Layout from "./components/common/Layout";
import ROUTES from "./config/Routes";
import Store from "./states/Store";
import { theme } from "./style/globalStyle";
import { withTranslation } from "react-i18next";
import Complete from "./containers/Complete";

export let jwt = null;

const AppRoute = ({
  home,
  path,
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <Route
    {...rest}
    path={path}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

function NoMatch({ home }) {
  let location = useLocation();
  let history = useHistory();

  return (
    <Layout home={home}>
      <Container style={{ padding: "4em", margin: "0 auto", width: "50%" }}>
        <Paper>
          <Alert severity="warning">
            <AlertTitle>Chemin introuvable</AlertTitle>
            <p>Le chemin {location.pathname} n'existe pas !</p>
            <Typography variant="inherit">
              <Button onClick={() => history.push("/")}>
                Revenir à la page d'accueil
              </Button>
            </Typography>
          </Alert>
        </Paper>
      </Container>
    </Layout>
  );
}

function App() {
  return (
    <Store>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Router>
              <Switch>
                <AppRoute
                  exact
                  path={`/${ROUTES.COMPLETE}`}
                  layout={Layout}
                  component={Complete}
                />
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </Router>
          </ScopedCssBaseline>
        </ThemeProvider>
      </StyledEngineProvider>
    </Store>
  );
}

export default withTranslation()(App);
