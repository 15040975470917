
export async function eaFetch(api, verb, url, data) {

  let requestOptions = {
    method: verb,
    headers: {
      "Content-Type": "application/json"
    }
  };

  if (verb !== "GET") {
    requestOptions = { body: JSON.stringify(data) , ...requestOptions };
  }

  const response = await fetch(api + url, requestOptions);

  if (response.ok) {
    return response.json();
  } else {
    let errorMsg = await response.json();
    let error = new Error();

    if (errorMsg) {
      /* eslint-disable no-throw-literal */
      throw { ...error, message: errorMsg };
    } else {
      throw { ...error, message: "Internal server error" };
    }
  }
}