const Reducer = (state, action) => {
  switch (action.type) {
    case "LOADER":
      return {
        ...state,
        loader: action.loader,
      };
    case "ERRORS":
      return {
        ...state,
        errors: action.errors,
      };
    case "REGISTRATION_THEME":
      return {
        ...state,
        theme: action.theme,
      };
    default:
      return state;
  }
};

export default Reducer;
