/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import i18next from "i18next";
import { getEnv } from "../config/Config";


const RegistrationNotFoundBlock = ({app}) => {

  return (
<>
    {i18next.t('nonExistentRegistration1')}<br /><br />
    {i18next.t('nonExistentRegistration2')}<br />
    {app === 'fo' ? (
      <>
        {i18next.t('nonExistentRegistration3FO')}<br />
        {i18next.t('nonExistentRegistration4FO')}<a href={i18next.t('support.url')}>{i18next.t('nonExistentRegistration4FObis')}</a>.<br /><br />
      </>
    ) : (
      <>
        {i18next.t('nonExistentRegistration3DO')}<br />
        {i18next.t('nonExistentRegistration4DO')}<br /><br />
      </>
    )}
    {i18next.t('nonExistentRegistration5')}<br />
    {i18next.t('nonExistentRegistration6')}<a href={getEnv(`REACT_APP_URL_${app.toUpperCase()}`) || getEnv(`REACT_APP_URL_DO`)}>{i18next.t('here')}</a>.
  </>
  );
};

export default RegistrationNotFoundBlock;
