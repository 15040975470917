import { Container, Collapse, Typography, Alert, AlertTitle } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from "react";
import { Context } from "../../states/Store";
import i18next from "../../i18n/i18n";
import Moment from "react-moment";
import EAFooterLinks from "./EAFooterLinks";
import { withTranslation } from "react-i18next";
import { useQuery } from "../../config/Config";
import { APPS } from "../../services/Constantes";

const FO_BG_IMG = {
  backgroundImage:
    "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_bg_xs.png)",
  "@media (orientation: portrait), (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_bg_md.png)",
  },
  "@media (min-width: 640px) and (orientation: landscape)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_bg_md.png)",
  },
  "@media (min-width: 640px) and (orientation: portrait)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_bg_lg.png)",
  },
  "@media (min-width: 640px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 640px) and (min-resolution: 144dpi)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_bg_lg.png)",
  },
  "@media (min-width: 1280px)  and (orientation: landscape)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_bg_lg.png)",
  },
  "@media (min-width: 1280px)  and (orientation: portrait)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_bg_xl.png)",
  },
  "@media (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 1280px) and (min-resolution: 144dpi)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_bg_xl.png)",
  },
  "@media (min-width: 2560px)  and (orientation: landscape)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_bg_xl.png)",
  },
};

const DO_BG_IMG = {
  backgroundImage:
    "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_do_bg_xs.jpg)",
  "@media (orientation: portrait), (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_do_bg_md.jpg)",
  },
  "@media (min-width: 640px) and (orientation: landscape)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_do_bg_md.jpg)",
  },
  "@media (min-width: 640px) and (orientation: portrait)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_do_bg_lg.jpg)",
  },
  "@media (min-width: 640px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 640px) and (min-resolution: 144dpi)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_do_bg_lg.jpg)",
  },
  "@media (min-width: 1280px)  and (orientation: landscape)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_do_bg_lg.jpg)",
  },
  "@media (min-width: 1280px)  and (orientation: portrait)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_do_bg_xl.jpg)",
  },
  "@media (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 1280px) and (min-resolution: 144dpi)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_do_bg_xl.jpg)",
  },
  "@media (min-width: 2560px)  and (orientation: landscape)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/eattestations_do_bg_xl.jpg)",
  },
};

const APP_365_BG_IMG =  {
  backgroundImage:
    "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/365_bg_xs.png)",
  "@media (orientation: portrait), (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/365_bg_md.png)",
  },
  "@media (min-width: 640px) and (orientation: landscape)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/365_bg_md.png)",
  },
  "@media (min-width: 640px) and (orientation: portrait)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/365_bg_lg.png)",
  },
  "@media (min-width: 640px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 640px) and (min-resolution: 144dpi)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/365_bg_lg.png)",
  },
  "@media (min-width: 1280px)  and (orientation: landscape)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/365_bg_lg.png)",
  },
  "@media (min-width: 1280px)  and (orientation: portrait)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/365_bg_xl.png)",
  },
  "@media (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 1280px) and (min-resolution: 144dpi)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/365_bg_xl.png)",
  },
  "@media (min-width: 2560px)  and (orientation: landscape)": {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/365_bg_xl.png)",
  },
};

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    backgroundSize: "cover",

    [theme.breakpoints.down('sm')]: {
      overflow: "hidden",
    },
    "& .childSection": {
      width: "100%",
      flex: "1",
      display: "flex",
      margin: "0 auto",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  do: DO_BG_IMG,
  fo: FO_BG_IMG,
  365: APP_365_BG_IMG
}));

function Layout(props) {
  const css = useStyles();
  const [state] = useContext(Context);
  const [display, setDisplay] = React.useState(true);
  const app = useQuery().get("app");
  const [registrationTheme, setRegistrationTheme] = React.useState(
    APPS?.includes(app?.toLowerCase()) ? app?.toLowerCase() : "do"
  );

  React.useEffect(() => {
    setDisplay(true);
  }, [state.errors]);

  React.useEffect(() => {
    setRegistrationTheme(state.theme);
  }, [state.theme]);

  return (
    <>
      <Container className={`${css.container} ${css[registrationTheme]}`}>
        {state.errors && (
          <Collapse in={display}>
            <Alert
              variant="filled"
              severity="error"
              onClose={() => setDisplay(!display)}
            >
              <AlertTitle>
                <Typography variant="h5">
                  {i18next.t("problemDetected")}
                  <Typography variant="caption">
                    {", "}
                    <Moment format="YYYY/MM/DD HH:mm:ss TZ">
                      {new Date()}
                    </Moment>
                  </Typography>
                </Typography>
              </AlertTitle>
              <pre>{state?.errors}</pre>
            </Alert>
          </Collapse>
        )}

        <div className="childSection">{props.children}</div>
        <EAFooterLinks />
      </Container>
    </>
  );
}

export default withTranslation()(Layout);
