/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import i18next from "i18next";
import { Button, Grid, Typography } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    
  userExists: {
    margin: "0rem 0.5rem 1rem 0.5rem",
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
  },
  })
);

const UserExistsBlock = (loaderComplete, registrationTheme) => {
  const classes = useStyles();

  return <>
  <Typography className={classes.userExists}>
    {i18next.t('userAlreadyExist')}
  </Typography>

  <Grid
    item
    xs={12}
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    className="formBtnGrp"
  >
    <Button
      type="submit"
      variant="contained"
      size="medium"
      color="primary"
      className={(loaderComplete ? "loaderComplete" : "", registrationTheme === 'fo' ? "blueBtn" : "greenBtn")}
    >
      {i18next.t("send")}
    </Button>
  </Grid>
</>;
};

export default UserExistsBlock;
