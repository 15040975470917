import { Grid, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from "react";
import RegistrationLoader from "./RegistrationLoader";
import { Context } from "../../states/Store";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "40%",
    maxWidth: "400px",
    height: "auto",
    animation: "load 1.5s",
    paddingBottom: "2rem",

    [theme.breakpoints.down('lg')]: {
      width: "80%",
      marginTop: '1rem',
      marginBottom: '1rem',
    },

    [theme.breakpoints.down('sm')]: {
      width: "90%",
    },

    "& .logo": {
      [theme.breakpoints.down('md')]: {
        width: "200px",
      },
    },

    "& .textContainer": {
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },

    "& .separator": {
      width: "50px",
      height: "2px",
      background: "#5c5b5b",
      margin: "0 auto",
      marginBottom: "1.2rem",
      borderRadius: "5px",
    },
  },
}));

function RegistrationContainer(props) {
  const classes = useStyles();
  const [state] = useContext(Context);

  return <>
    {state.loader && <RegistrationLoader />}
    {!state.loader && (
      <>
        <img
          className="logo"
          alt="e-Attestations"
          src="../assets/logo_icon.png"
        />
        <Paper className={classes.root} elevation={3}>
          <Grid justifyContent="center" alignItems="center" container spacing={0}>
            <Grid style={{ textAlign: "center" }} item xs={12}>
            </Grid>
            <Grid item xs={12}>
              {props.children}
            </Grid>
          </Grid>
        </Paper>
      </>
    )}
  </>;
}

export default RegistrationContainer;
