import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { darkGrey, primary } from "../../style/globalStyle";
import Loader from "react-loader-spinner";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& span": {
      marginTop: "0.8rem",
      color: darkGrey,
      fontSize: "1rem",
    },
  },
}));

function RegistrationLoader(props) {
  const css = useStyles();

  return (
    <div className={css.root}>
      <Loader
        type="Oval"
        color={primary}
        height={props.size}
        width={props.size}
        timeout={100000}
      />
      <span>{props.text}</span>
    </div>
  );
}

export default RegistrationLoader;

RegistrationLoader.defaultProps = {
  size: 100,
  text : i18next.t("loading")
};