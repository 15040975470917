import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Link,
} from "@mui/material";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import RegistrationLoader from "../components/common/RegistrationLoader";
import { useParams } from "react-router";
import { complete, completeWithExistingUser, completeWithExistingUserOnAccount, verifyRegistrationEmail } from "../services/RegistrationService";
import { theme } from "../style/globalStyle";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import i18next from "i18next";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { LANGS } from "../i18n/i18n";
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import UserExistsBlock from "./UserExistsBlock";
import { preferredCountries, REGEXP_EMAIL, REGEX_NAME } from "../services/Constantes";
import UserBlacklistedBlock from "./UserBlacklistedBlock";
import RegistrationNotFoundBlock from "./RegistrationNotFoundBlock";
import { useStyles } from "../style/completeStyle";


const errorDisplayBloc = (app) => (
  <>
    {app === 'fo' ? (
      <>
        {i18next.t('errors.saveInfosDE')}<a href={i18next.t('support.url')}>{i18next.t('nonExistentRegistration4FObis')}</a>.<br /><br />
      </>
    ) : (
      <>
        {i18next.t('errors.saveInfosDO')}<br /><br />
      </>
    )}
  </>
);

function RegistrationFormBlock({email, registrationTheme}) {
  const classes = useStyles();
  const [errorComplete, setErrorComplete] = React.useState(null);
  const [loaderComplete, setLoaderComplete] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [lang, setLang] = React.useState(LANGS.includes(i18next.language) ? i18next.language : 'fr');
  const [phoneEvent, setPhoneEvent] = React.useState(null);
  const [phoneCountry, setPhoneCountry] = React.useState("33");
  const [showSamePassword, setShowSamePassword] = React.useState(false);
  const [isUserExist, setIsUserExist] = React.useState(false);
  const [isUserExistOnAccount, setIsUserExistOnAccount] = React.useState(false);
  const [isUserBlacklisted, setIsUserBlacklisted] = React.useState(false);
  const [currentEmail, setCurrentEmail] = React.useState("");
  const { register, setValue, control, errors, handleSubmit, watch } = useForm({ mode: "onChange", delayError: 500 });
  let { registrationKey } = useParams();


  useEffect(() => {
    if (phoneCountry) {
      setValue("internationalPhone", "+" + phoneCountry);
    }
  }, [phoneCountry, setValue])

  const onSubmit = async (data) => {
    data.lang = lang;
    if (!isUserExist && !isUserExistOnAccount) {
      addPhoneEventData(data);
    }

    setLoaderComplete(true);
    setErrorComplete(null);

    try {
      let completeResp;
      if (isUserExist) {
        completeResp = await completeWithExistingUser(registrationKey, { email: data.email });
      } else {
        completeResp = await complete(registrationKey, { ...data, email: data.email });
      }
      if (completeResp.httpStatus === "OK") {
        window.location.href = completeResp.body?.redirectionUrl
      } else if (completeResp.httpStatus === "NOT_FOUND") {
        setErrorComplete(<RegistrationNotFoundBlock app={registrationTheme}></RegistrationNotFoundBlock>);
      }
      else {
        let messages = completeResp?.messages;
        if (messages != null) {
          setErrorComplete(i18next.t("errors." + messages[0].text));
        } else {
          setErrorComplete(errorDisplayBloc(registrationTheme));
        }
      }
      setLoaderComplete(false);
    } catch (e) {
      setErrorComplete(errorDisplayBloc(registrationTheme));
      setLoaderComplete(false);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLangChange = (e) => {
    setLang(e.target.value);
    localStorage.setItem("i18nextLng", e.target.value);
    window.location.href = window.location.pathname + "?lng=" + e.target.value;
  }

  const addPhoneEventData = (data) => {
    data.phoneRegion = phoneEvent[1].countryCode.toUpperCase();
    data.internationalPhone = "+" + data.internationalPhone
    data.nationalPhone = phoneEvent[0].replace(phoneEvent[1].dialCode, 0);
  }

  const checkEmailExists = async (value) => {
    try {
      const emailExists = await verifyRegistrationEmail(registrationKey, value);
      const messages = emailExists?.messages;
      setIsUserExist(false);
      setIsUserExistOnAccount(false);
      setIsUserBlacklisted(false);
      if (emailExists.httpStatus !== "OK" && messages != null) {
        if (messages[0].text === "ERROR_EMAIL_EXISTS_ON_ACCOUNT") {
          setIsUserExistOnAccount(true);
        } else if (messages[0].text === "ERROR_EMAIL_EXISTS") {
          setIsUserExist(true);
        } else if (messages[0].text === "ERROR_EMAIL_BLACKLISTED") {
          setIsUserBlacklisted(true);
        } else {
          return i18next.t("errors." + messages[0].text);
        }
      } else if (emailExists.httpStatus !== "OK") {
        return i18next.t(`errors.${registrationTheme === 'fo' ? "saveInfosDE" : "saveInfosDO"}`);
      }
    } catch (e) {
      return i18next.t(`errors.${registrationTheme === 'fo' ? "saveInfosDE" : "saveInfosDO"}`);
    }
  }

  const rejectInvitationAndRedirect = async (addrEmail) => {
    try {
      let completeResp = await completeWithExistingUserOnAccount(registrationKey, { email: addrEmail });
      if (completeResp) {
        window.location.href = completeResp.body?.redirectionUrl
      } else {
        setErrorComplete(i18next.t(`errors.${registrationTheme === 'fo' ? "saveInfosDE" : "saveInfosDO"}`));
      }
    } catch (e) {
      return i18next.t(`errors.${registrationTheme === 'fo' ? "saveInfosDE" : "saveInfosDO"}`);
    }
  }


  const checkPassword = (pwd) => {
    let matchedCase = [];
    matchedCase.push("[$@$!*%?_+-]"); // Special Character
    matchedCase.push("[A-Z]");      // Uppercase Alpabates
    matchedCase.push("[a-z]");     // Lowercase Alphabates
    matchedCase.push("[0-9]");      // Numbers

    // Check the conditions
    let ctr = 0;
    for (let i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(pwd)) {
        ctr++;
      }
    }
    if (!new RegExp("([a-zA-Z0-9])\\1{2,}").test(pwd)) {//No more than two repeated caracters in a row
      ctr++;
    }
    if (ctr < 5 || pwd.length < 10) {
      return i18next.t("errors.password.pattern");
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="registrationHeader"
      >
        <Typography variant="h1" classes={{ root: "headerLabel" }}>{i18next.t("registration")}</Typography>

        {!isUserExist && !isUserExistOnAccount && (
          <Select
            value={lang}
            onChange={handleLangChange}
            IconComponent={KeyboardArrowDownIcon}
            className="langSelect"
            renderValue={value => value.toUpperCase()}
          >
            {LANGS.map(langOpt =>
              <MenuItem key={langOpt}
                classes={{ root: classes.langItem }}
                className={langOpt === lang ? "hidden" : ""}
                value={langOpt}
              >
                {langOpt.toUpperCase()}
              </MenuItem>
            )}
          </Select>
        )}
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          className="formContainer"
          justifyContent="center"
          alignItems="center"
          container
          spacing={0}
        >

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              error={!!errors.email}
              name="email"
              style={{ width: "100%" }}
              className="input-label"
              label={i18next.t("email")}
              inputRef={register({
                required: "errors.email.required",
                pattern: {
                  value: REGEXP_EMAIL,
                  message: "errors.email.pattern",
                },
                validate: value => checkEmailExists(value)
              })}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              helperText={errors.email ? i18next.t(errors.email.message) : ""}
              defaultValue={email}
              onChange={(event) => setCurrentEmail(event.target.value)}
            />
          </Grid>
          {isUserBlacklisted && (
            <UserBlacklistedBlock></UserBlacklistedBlock>
          )}
          {isUserExist && !isUserExistOnAccount && !isUserBlacklisted && (
            <UserExistsBlock loaderComplete registrationTheme>

            </UserExistsBlock>)}
          {!isUserExist && isUserExistOnAccount && !isUserBlacklisted && (

            <Typography className={classes.userExists}>
              {i18next.t('userAlreadyExistOnAccount')} <Link onClick={() => rejectInvitationAndRedirect(currentEmail)} underline="always">{i18next.t('clickHere')}</Link>
            </Typography>

          )}
          {!isUserExist && !isUserExistOnAccount && !isUserBlacklisted && (
            <>
              {/* First Name */}
              <Grid item xs={12}>
                <TextField
                  error={!!errors.firstName}
                  name="firstName"
                  style={{ width: "100%" }}
                  className="input-label"
                  label={i18next.t("firstName")}
                  inputRef={register({
                    required: "errors.firstName.required",
                    pattern: {
                      value: REGEX_NAME,
                      message: "errors.firstName.pattern"
                    }
                  })}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  helperText={errors.firstName ? i18next.t(errors.firstName.message) : ""}
                />
              </Grid>

              {/* Last Name */}
              <Grid item xs={12}>
                <TextField
                  error={!!errors.lastName}
                  name="lastName"
                  style={{ width: "100%" }}
                  className="input-label"
                  label={i18next.t("lastName")}
                  inputRef={register({
                    required: "errors.lastName.required",
                    pattern: {
                      value: REGEX_NAME,
                      message: "errors.lastName.pattern"
                    }
                  })}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  helperText={errors.lastName ? i18next.t(errors.lastName.message) : ""}
                />
              </Grid>

              {/* Function */}
              <Grid item xs={12}>
                <TextField
                  error={!!errors.function}
                  name="function"
                  style={{ width: "100%" }}
                  className="input-label"
                  label={i18next.t("function")}
                  inputRef={register({ required: "errors.function.required" })}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  helperText={errors.function ? i18next.t(errors.function.message) : ""}
                />
              </Grid>

              {/* Phone */}
              <Grid item xs={12} className="input-label">
                <InputLabel shrink className={errors.internationalPhone ? "Mui-error" : ""}>{i18next.t("phone")}</InputLabel>
                <Controller
                  as={(
                    <PhoneInput
                      inputClass={errors.internationalPhone ? "phone-error" : ""}
                      buttonClass={errors.internationalPhone ? "phone-error" : ""}
                      country={preferredCountries[0]}
                      preferredCountries={preferredCountries}
                      preserveOrder={['preferredCountries']}
                      countryCodeEditable={false}
                      inputProps={{
                        name: 'internationalPhone',
                        required: true
                      }}
                      name="internationalPhone"
                      enableSearch
                      enableLongNumbers
                      disableSearchIcon
                      localization={i18next.t("countries", { returnObjects: true })}
                      searchPlaceholder={i18next.t("search")}
                    />
                  )}
                  name="internationalPhone"
                  rules={{ required: true, validate: value => isValidPhoneNumber("+" + value) }}
                  onChange={e => {
                    setPhoneCountry(e[1].dialCode);
                    setPhoneEvent(e); return e[0]
                  }}
                  control={control}
                  defaultValue=""
                />
                <FormHelperText className={errors.internationalPhone ? "Mui-error" : ""}>
                  {errors.internationalPhone ? i18next.t("errors.phone.format") : ""}
                </FormHelperText>
              </Grid>

              {/* Password */}
              <Grid item xs={12}>
                <FormControl
                  error={!!errors.password}
                  style={{ width: "100%" }}
                  className="input-label"
                  variant="outlined"
                >
                  <InputLabel htmlFor="password-id" shrink>
                    {i18next.t("password")}
                  </InputLabel>
                  <OutlinedInput
                    inputRef={register({
                      required: "errors.password.required",
                      validate: value => checkPassword(value)
                    })}
                    name="password"
                    id="password-id"
                    autoComplete="on"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={i18next.t("passwordBtnAriaLabel")}
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large">
                          {showPassword ?
                            <Tooltip title={i18next.t("hide")}><VisibilityOff /></Tooltip> :
                            <Tooltip title={i18next.t("show")}><Visibility /></Tooltip>
                          }
                        </IconButton>
                      </InputAdornment>
                    }
                    labelwidth={100}
                  />
                  <FormHelperText>
                    {errors.password ? i18next.t(errors.password.message) : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>


              {/* Password Confirm */}
              <Grid item xs={12} className="pwdConfirmItem">
                <FormControl
                  error={!!errors.samePassword}
                  style={{ width: "100%" }}
                  className="input-label"
                  variant="outlined"
                >
                  <InputLabel htmlFor="confirm-password-id" shrink>
                    {i18next.t("confirmPassword")}
                  </InputLabel>
                  <OutlinedInput
                    inputRef={register({
                      required: "errors.password.required",
                      validate: (value) =>
                        value !== watch("password")
                          ? "errors.password.confirm"
                          : undefined,
                    })}
                    name="samePassword"
                    id="confirm-password-id"
                    autoComplete="on"
                    type={showSamePassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={i18next.t("passwordBtnAriaLabel")}
                          onClick={() => setShowSamePassword(!showSamePassword)}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large">
                          {showSamePassword ?
                            <Tooltip title={i18next.t("hide")}><VisibilityOff /></Tooltip> :
                            <Tooltip title={i18next.t("show")}><Visibility /></Tooltip>
                          }
                        </IconButton>
                      </InputAdornment>
                    }
                    labelwidth={180}
                  />
                  <FormHelperText>
                    {errors.samePassword ? i18next.t(errors.samePassword.message) : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} className="cguItem">
                <FormControl error={!!errors.cgu}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        inputRef={register({ required: "errors.cgu.required" })}
                        name="cgu"
                      />
                    }
                    label={
                      <>
                        <Typography classes={{ root: "cguLabel" }} align="left">
                          {i18next.t("cguLabel")}
                        </Typography>
                        <a href={i18next.t("footerLinks.CGU.url")}
                          target="_blank"
                          style={{ color: theme.palette.primary.main }}
                          rel="noopener noreferrer">
                          {i18next.t("cgu")}
                        </a>
                        {" *"}
                      </>
                    }
                  />
                  <FormHelperText>
                    {errors.cgu ? i18next.t(errors.cgu.message) : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="formBtnGrp"
              >
                <Typography variant="body2">{i18next.t("requiredFields")}</Typography>
                <Button
                  type="submit"
                  variant="contained"
                  size="medium"
                  color="primary"
                  className={(loaderComplete ? "loaderComplete" : "", registrationTheme === 'fo' ? "blueBtn" : "greenBtn")}
                >
                  {i18next.t("send")}
                </Button>
              </Grid>

            </>
          )}
        </Grid>

        {loaderComplete && (
          <>
            <br />
            <RegistrationLoader text={i18next.t("loadingText")} size={50} />
          </>
        )}
        {errorComplete && <div className="error">{errorComplete}</div>}
      </form>
    </div>
  );
}

export default RegistrationFormBlock;
