import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  invalidRegistration: {
    margin: "2rem 0.5rem 0 0.5rem",
    textAlign: 'center',
    whiteSpace: 'pre-wrap'
  },
  userExists: {
    margin: "0rem 0.5rem 1rem 0.5rem",
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    "& a": {
      cursor: "pointer"
    }
  },
  root: {
    "& .error": {
      textAlign: "center",
      margin: "1rem",
      marginBottom: 0,
      color: theme.error,
      whiteSpace: 'pre-wrap'
    },
    "& .registrationHeader": {
      width: "90%",
      margin: "0 auto",
      marginTop: "1.5rem",
      display: "flex",

      [theme.breakpoints.down("500")]: {
        width: "95%",
      },

      "& .headerLabel": {
        fontSize: "1.5rem",
        fontWeight: "500"
      },

      "& .langSelect": {
        fontSize: "0.75rem",
        fontWeight: "300",
        "& .MuiSelect-select": {
          paddingRight: "1.125rem"
        },
        "& .MuiSelect-icon": {
          fontSize: "1rem",
          top: "calc(50% - 0.5rem)"
        }
      }
    },
    "& .formContainer": {
      width: "90%",
      margin: "0 auto",
      marginTop: "2rem",

      [theme.breakpoints.down("500")]: {
        width: "95%",
      },

      "& .MuiGrid-item": {
        marginBottom: "2rem",

        "& input": {
          boxSizing: 'inherit'
        }
      },

      "& label": {
        lineHeight: "1px"
      },

      "& .MuiInputLabel-shrink": {
        transform: "translate(0px, -12px) scale(0.75)"
      },

      "& .react-tel-input": {
        "& input": {
          width: "100%"
        },
        "& .country-list": {
          maxHeight: "16rem",
          "& .search": {
            "& input": {
              marginLeft: "-0.25rem"
            }
          }
        }
      },

      "& legend": {
        maxWidth: "0px"
      },

      "& .pwdConfirmItem": {
        marginBottom: "1rem"
      },

      "& .cguItem": {
        width: "100%",
        "& .MuiTypography-root": {
          fontSize: "0.75rem"
        }
      },

      "& .MuiFormHelperText-contained": {
        marginLeft: 0
      },

      "& .formBtnGrp": {
        marginBottom: 0,
        "& .MuiTypography-root": {
          opacity: 0.7,
          fontSize: "0.75rem"
        },
        "& .MuiButton-root": {
          textTransform: "none",
          padding: "0.375rem 1.5rem",
          "&.loaderComplete": {
            pointerEvents: "none",
            cursor: "inherit",
            opacity: 0.5
          },
          "&.greenBtn:hover": {
            backgroundColor: theme.palette.primary.main
          },
          "&.blueBtn:hover": {
            backgroundColor: theme.palette.secondary.main
          }
        }
      },
      "& .phone-error": {
        borderColor: "#f44336"
      },
    },
    // This classes is set to avoid that the textfield labels are hidden by the above field
    "& .input-label": {
      "& .MuiInputLabel-root": {
        overflow: "visible !important"
      },
    },
  },
  langItem: {
    fontSize: "0.75rem",
    fontWeight: 300,
    "&.MuiListItem-gutters": {
      padding: "0.125rem 0.75rem"
    },
    "&.hidden": {
      display: "none"
    }
  }
}));