import React from "react";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import i18next from "i18next";
import { Typography } from "@mui/material";
import { Trans } from "react-i18next";

const useStyles = makeStyles(() =>
  createStyles({

    userExists: {
      margin: "0rem 0.5rem 1rem 0.5rem",
      textAlign: 'center',
      whiteSpace: 'pre-wrap'
    },
  })
);

const UserBlacklistedBlock = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.userExists}>
        <Trans i18nKey="errors.ERROR_EMAIL_BLACKLISTED">
          {i18next.t('errors.ERROR_EMAIL_BLACKLISTED')} <a href={i18next.t('support.url')}>{i18next.t('here')}</a>.
        </Trans>
      </Typography>
    </>
  );
};

export default UserBlacklistedBlock;
